import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import Share from "../components/share"
import ContactForm from "../components/contactForm"
import styled from "@emotion/styled"

export default ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout pathEn="/en/">
      <SEO
        title={post.frontmatter.metaTitle}
        description={post.frontmatter.metaDescription}
      />
      <Helmet>
        <script type="application/ld+json">{`
    {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Actualités",
        "item": "https://www.docteurphilipperoure.com/actus/"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "${post.frontmatter.title}",
        "item": "https://www.docteurphilipperoure.com${post.fields.slug}"
      }]
  }
`}</script>
        <meta
          property="og:image"
          content={`https://www.docteurphilipperoure.com${post.frontmatter.image.childImageSharp.fluid.src}`}
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="813" />
        <meta property="og:image:height" content="464" />
        <meta
          property="og:url"
          content={`https://www.docteurphilipperoure.com${post.fields.slug}`}
        />
        <meta property="og:title" content={post.frontmatter.metaTitle} />
        <meta
          property="og:description"
          content={post.frontmatter.metaDescription}
        />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="1086796511711258" />
      </Helmet>
      <WrapperTitle>
        <WrapperTitleText>
          <Breadcrumb>
            <Link to="/">Accueil</Link> / <Link to="/actus/">Actualités</Link>
          </Breadcrumb>
          <H1 dangerouslySetInnerHTML={{ __html: post.frontmatter.title }} />
          <Intro dangerouslySetInnerHTML={{ __html: post.frontmatter.intro }} />
        </WrapperTitleText>

        <WrapperTitleImage>
          <Img
            fluid={post.frontmatter.image.childImageSharp.fluid}
            css={{ margin: `0 auto` }}
            alt={post.frontmatter.imageAlt}
          />
        </WrapperTitleImage>
      </WrapperTitle>

      <Share slug={post.fields.slug} title={post.frontmatter.title} />

      <WrapperText dangerouslySetInnerHTML={{ __html: post.html }} />

      <ContactForm about={post.frontmatter.about} title="Page Actualités" />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        metaTitle
        metaDescription
        intro
        about
        image {
          childImageSharp {
            fluid(maxWidth: 813) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      html
      fields {
        slug
      }
    }
  }
`

const WrapperTitle = styled.div`
  position: relative;
  @media (max-width: 1000px) {
    display: block;
  }
`

const WrapperTitleText = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  max-width: 50%;
  background-color: white;
  transform: translateY(-50%);
  padding: 1.5em 1em 1em 1.2em;
  border-radius: 2px;
  flex: 0 0 40%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 1000px) {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    max-width: 100%;
    transform: translateY(0%);
    top: 0;
    border-radius: 7px 7px 0 0;
    padding: 1.15em 0.75em 0.75em 0.9em;
  }
`

const WrapperTitleImage = styled.div`
  margin-left: 40%;
  max-width: 60%;
  flex: 0 0 60%;
  z-index: 0;
  @media (max-width: 1000px) {
    margin-left: 0;
    width: 100%;
    max-width: 100%;
    transform: translateY(0%);
    border-radius: 0 0 7px 7px;
  }
`

const Breadcrumb = styled.div`
  text-align: center;
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1em;
  a {
    color: #4f5767;
    text-decoration: none;
  }
  @media (max-width: 678px) {
    font-size: 0.87em;
    margin-bottom: 10px;
  }
`

const H1 = styled.h1`
  color: rgba(44, 44, 44, 0.96);
  font-family: Raleway, "Times new roman", serif;
  @media (max-width: 678px) {
    font-size: 1.85em;
    line-height: 1.2em;
  }
`

const Intro = styled.p`
  font-size: 1.18em;
  line-height: 158%;
  @media (max-width: 678px) {
    font-size: 1.15em;
    line-height: 155%;
  }
`

const WrapperText = styled.div`
  background-color: white;
  border-radius: 5px;
  margin: 0.5em auto 0.5em;
  padding: 1.5em 1.5em 1em 1.5em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 678px) {
    padding: 1em 0.7em 1em;
  }
`
